import type IsoDate from '../../../models/IsoDate';
import { requestAndParse, requestSuccessful } from '../request';

const createPromoCode = async (
    code: string,
    expirationDate: IsoDate,
    numberOfFreeDays: number,
    note: string,
) => {
    const route = 'admin/create-promo-code';
    const payload = {
        code,
        expirationDate,
        numberOfFreeDays,
        note,
    };

    const response = await requestAndParse(route, payload);

    const success = requestSuccessful(response);
    const message = response.body?.message as string;

    return { success, message };
};

export default createPromoCode;
