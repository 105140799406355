import type DbDataModel from '../../../models/DbDataModel';
import { requestAndParse, requestSuccessful } from '../request';

const getChartReviews = async () => {
    const route = 'admin/get-chart-reviews';
    const payload = {};

    const response = await requestAndParse(route, payload, 'GET');

    const success = requestSuccessful(response);
    const chartReviews = response.body?.chartReviews as DbDataModel[];
    const message = response.body?.message as string;

    return { success, chartReviews, message };
};

export default getChartReviews;
