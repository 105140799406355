import React, { useState, type FC, useEffect } from 'react';
import { type Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import type UserModel from '../models/UserModel';
import api from '../services/api';
import type DbDataModel from '../models/DbDataModel';
import { connectAccount, disconnectAccount } from '../helpers/AccountHelpers';
import LoadingView from './LoadingView';
import Table from './Table';

const AccountConnectTab: FC<{
    user: UserModel | undefined;
    toast: React.RefObject<Toast>;
}> = ({ user, toast }) => {
    const [data, setData] = useState<DbDataModel[]>([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(true);

    const updateData = async () => {
        setLoading(true);
        const { teachingCouples, message, success } =
            await api.getAccountConnectInfo(user?.id ?? 0);
        if (success) {
            setData(teachingCouples);
            setErrorMessage('');
        } else {
            setData([]);
            setErrorMessage(message);
        }

        setLoading(false);
    };

    const connect = async (teacher: DbDataModel) => {
        await connectAccount(user?.id ?? 0, teacher, toast);
        setData([...data]);
    };

    const disconnect = async (teacher: DbDataModel) => {
        await disconnectAccount(user?.id ?? 0, teacher, toast);
        setData([...data]);
    };

    useEffect(() => {
        void updateData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id]);

    return (
        <div className="min-w-[800px] min-h-[300px] flex justify-center items-center">
            {loading && <LoadingView />}
            {!loading && errorMessage && (
                <div className="text-primary text-xl">
                    Error: {errorMessage}
                </div>
            )}
            {!loading && !errorMessage && (
                <Table
                    title="Account Connect"
                    data={data}
                    onClick={(teacher) => {
                        confirmDialog({
                            header: `Confirm ${
                                teacher.connected ? 'Disconnect' : 'Connect'
                            }`,
                            message: `Are you sure you want to ${
                                teacher.connected ? 'disconnect' : 'connect'
                            } user ${user?.username ?? ''} and teacher ${
                                (teacher?.username ?? '') as string
                            }?`,
                            accept: teacher.connected
                                ? () => {
                                      void disconnect(teacher);
                                  }
                                : () => {
                                      void connect(teacher);
                                  },
                        });
                    }}
                />
            )}
        </div>
    );
};

export default AccountConnectTab;
