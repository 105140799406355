import type DbDataModel from '../../../models/DbDataModel';
import { requestAndParse, requestSuccessful } from '../request';

const getAuditReport = async () => {
    const route = 'admin/get-audit-report';
    const payload = {};

    const response = await requestAndParse(route, payload, 'GET');

    const success = requestSuccessful(response);
    const auditReport = response.body?.auditReport as DbDataModel[];
    const message = response.body?.message as string;

    return { success, auditReport, message };
};

export default getAuditReport;
