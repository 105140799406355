import React, { useState, type FC, useEffect } from 'react';
import type UserModel from '../models/UserModel';
import api from '../services/api';
import type DbDataModel from '../models/DbDataModel';
import LoadingView from './LoadingView';
import Table from './Table';

const PaymentHistoryTab: FC<{
    user: UserModel | undefined;
}> = ({ user }) => {
    const [data, setData] = useState<DbDataModel[]>([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(true);

    const updateData = async () => {
        setLoading(true);
        const { payments, message, success } = await api.getUserPayments(
            user?.id ?? 0,
        );
        if (success) {
            setData(payments);
            setErrorMessage('');
        } else {
            setData([]);
            setErrorMessage(
                message === 'Forbidden'
                    ? 'You do not have permission to access sales data.'
                    : message,
            );
        }

        setLoading(false);
    };

    useEffect(() => {
        void updateData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id]);

    return (
        <div className="w-[800px] min-h-[300px] flex justify-center items-center">
            {loading && <LoadingView />}
            {!loading && errorMessage && (
                <div className="text-primary text-xl">
                    Error: {errorMessage}
                </div>
            )}
            {!loading && !errorMessage && (
                <Table title="Payment History" data={data} />
            )}
        </div>
    );
};

export default PaymentHistoryTab;
