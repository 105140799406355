import tailwindConfig from '../tailwind.config';

type Colors = {
    primary: string;
    'primary-content': string;
    secondary: string;
    'secondary-content': string;
    accent: string;
    neutral: string;
    'base-100': string;
    'base-content': string;
};

const theme = {
    colors: tailwindConfig.daisyui.themes[0].peakday as Colors,
};

export default theme;
