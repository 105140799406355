import { requestAndParse, requestSuccessful } from '../request';

const setUserInfo = async (
    userId: number,
    firstName: string | undefined,
    lastName: string | undefined,
    email: string,
    phoneNumber: string | undefined,
    referralSource: string | undefined,
    username: string,
    // eslint-disable-next-line max-params
) => {
    const route = 'admin/set-user-info';
    const payload = {
        userId,
        firstName,
        lastName,
        email,
        phoneNumber,
        referralSource,
        username,
    };

    const response = await requestAndParse(route, payload);

    const success = requestSuccessful(response);
    const message = response.body?.message as string;

    return { success, message };
};

export default setUserInfo;
