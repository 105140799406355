import React, { type FC } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
    logout,
    setMenuOpen,
    useMenuOpen,
    useToken,
    useUsername,
} from '../redux';
import LoginForm from '../components/LoginForm';
import HamburgerMenu from '../assets/images/HamburgerMenu.svg';
import PeakDayLogo from '../assets/images/PeakDayLogo.png';
import NavMenu from '../components/NavMenu';

type LayoutProps = {
    children: React.ReactNode;
};

const Layout: FC<LayoutProps> = ({ children }) => {
    const username = useUsername();
    const token = useToken();

    const showMenu = useMenuOpen();
    const { width } = useWindowDimensions();

    if (!username || !token) {
        return <LoginForm />;
    }

    return (
        <div className="w-full h-full flex flex-row font-primary">
            <div
                className={
                    'flex flex-col w-96 h-full bg-primary fixed z-50 p-8' +
                    (showMenu ? '' : ' hidden')
                }
            >
                <img src={PeakDayLogo} width={103} height={103} />
                <NavMenu />
            </div>
            <div
                className={
                    'w-full h-full flex flex-col' + (showMenu ? ' ml-96' : '')
                }
            >
                <div className="w-full bg-secondary flex justify-between items-center">
                    <button
                        type="button"
                        className="btn btn-secondary text-primary m-4"
                        onClick={() => {
                            setMenuOpen(!showMenu);
                        }}
                    >
                        <img src={HamburgerMenu} />
                    </button>
                    <div className="flex flex-row justify-center items-center">
                        <span className="text-primary text-base">
                            {username}
                        </span>
                        <button
                            type="button"
                            className="btn btn-primary m-4"
                            onClick={() => {
                                confirmDialog({
                                    message:
                                        'Are you sure you want to log out?',
                                    accept: logout,
                                });
                            }}
                        >
                            Logout
                        </button>
                    </div>
                </div>
                <div
                    className="p-8"
                    style={{
                        width: width - (showMenu ? 384 : 0),
                    }}
                >
                    {children}
                </div>
            </div>
            <ConfirmDialog />
        </div>
    );
};

export default Layout;
