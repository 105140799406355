import React, { type FC } from 'react';
import { Oval } from 'react-loader-spinner';
import theme from '../theme';

const defaultSize = 100;

const LoadingSpinner: FC<{ size?: number }> = ({ size }) => {
    return (
        <Oval
            visible
            height={size ?? defaultSize}
            width={size ?? defaultSize}
            color={theme.colors.primary}
            secondaryColor={theme.colors['base-content']}
            wrapperStyle={{}}
            wrapperClass=""
            ariaLabel="loading"
            strokeWidth={2}
            strokeWidthSecondary={2}
        />
    );
};

export default LoadingSpinner;
