/** @type {import('tailwindcss').Config} */
module.exports = {
    content: ['./src/**/*.{js,jsx,ts,tsx}'],
    theme: {
        extend: {
            fontFamily: {
                primary: ['Poppins', 'sans-serif'],
            },
        },
    },
    daisyui: {
        themes: [
            {
                peakday: {
                    primary: '#54214D',
                    'primary-content': '#FFFFFF',
                    secondary: '#FFFFFF',
                    'secondary-content': '#FFFFFF',
                    accent: '#56FFFC',
                    neutral: '#CCCCCC',
                    'base-100': '#EEEEEE',
                    'base-content': '#C3C0C0',
                    '--rounded-btn': '9999px',
                    '--btn-text-case': 'uppercase',
                    '--btn-focus-scale': '.97',
                },
            },
        ],
    },
    plugins: [require('daisyui')],
};
