import React, { useState, type FC, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import Table from '../components/Table';
import type DbDataModel from '../models/DbDataModel';
import api from '../services/api';
import LoadingView from '../components/LoadingView';
import CreatePromoCodeDialog from '../components/CreatePromoCodeDialog';
import { addDays, getTodayIso } from '../utils/date';

const PromoCodes: FC<{ validOnly?: boolean }> = ({ validOnly }) => {
    const [data, setData] = useState<DbDataModel[]>([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [showPopup, setShowPopup] = useState(false);

    const toast = useRef<Toast>(null);

    const updateData = async () => {
        setLoading(true);
        const { promoCodes, message, success } = await api.getPromoCodes(
            validOnly,
        );
        if (success) {
            setData(promoCodes);
            setErrorMessage('');
        } else {
            setData([]);
            setErrorMessage(message);
        }

        setLoading(false);
    };

    useEffect(() => {
        void updateData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validOnly]);

    const deleteCode = async (row: DbDataModel) => {
        const { success, message } = await api.deletePromoCode(row.id);
        if (success) {
            if (validOnly) {
                setData(data.filter((dataRow) => dataRow !== row));
            } else {
                row.expiration_date = addDays(getTodayIso(), -1);
                setData([...data]);
            }

            toast.current?.show({
                severity: 'success',
                summary: 'Success',
                detail: `Promo Code "${row.code as string}" deactivated`,
            });
        } else {
            toast.current?.show({
                severity: 'warn',
                summary: 'Update Failed',
                detail: message ?? 'unknown error',
            });
        }
    };

    return (
        <>
            <Toast ref={toast} position="top-right" />
            <div className="flex flex-row justify-between items-center pb-4">
                <div className="text-primary text-3xl">Promo Codes</div>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                        setShowPopup(true);
                    }}
                >
                    Create Promo Code
                </button>
            </div>
            {loading && <LoadingView />}
            {!loading && errorMessage && (
                <div className="text-primary text-xl">
                    Error: {errorMessage}
                </div>
            )}
            {!loading && !errorMessage && (
                <Table
                    title={`${validOnly ? 'Valid' : 'All'} Promo Codes`}
                    data={data}
                    onClick={(row) => {
                        confirmDialog({
                            message: `Do you want to deactivate promo code "${
                                row.code as string
                            }"?`,
                            accept() {
                                void deleteCode(row);
                            },
                        });
                    }}
                />
            )}
            <CreatePromoCodeDialog
                showPopup={showPopup}
                setShowPopup={setShowPopup}
                toast={toast}
                updateData={updateData}
            />
        </>
    );
};

export default PromoCodes;
