import React, { useEffect, useRef } from 'react';
import { type FieldValues, useForm } from 'react-hook-form';
import { Toast } from 'primereact/toast';
import api from '../services/api';
import { setToken, setUsername, useToken, useUsername } from '../redux';

function LoginForm() {
    const toast = useRef<Toast>(null);
    const username = useUsername();
    const token = useToken();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data: FieldValues) => {
        const { success, token, message } = await api.login(
            data.username as string,
            data.password as string,
        );
        if (success) {
            setUsername(data.username as string);
            setToken(token);
        } else {
            toast.current?.show({
                severity: 'warn',
                summary: 'Login Failed',
                detail: message ?? 'unknown error',
            });
        }
    };

    useEffect(() => {
        if (username && !token) {
            toast.current?.replace({
                severity: 'info',
                summary: 'Session Expired',
                detail: 'Your session has expired. Please log in again.',
            });
        }
    }, [username, token]);

    return (
        <div className="w-full h-full flex justify-center items-center">
            <Toast ref={toast} position="top-right" />
            <form
                className="flex flex-col justify-center items-center"
                onSubmit={handleSubmit(onSubmit)}
            >
                <input
                    placeholder="username"
                    defaultValue={username}
                    {...register('username', { required: true })}
                    className="input input-bordered input-primary text-primary m-2"
                />
                {errors.username && (
                    <span className="text-red-500 mb-2">
                        This field is required
                    </span>
                )}
                <input
                    type="password"
                    placeholder="password"
                    defaultValue=""
                    {...register('password', { required: true })}
                    className="input input-bordered input-primary text-primary m-2"
                />
                {errors.password && (
                    <span className="text-red-500 mb-2">
                        This field is required
                    </span>
                )}
                <input type="submit" className="btn btn-primary m-2" />
            </form>
        </div>
    );
}

export default LoginForm;
