/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios, { type AxiosError } from 'axios';
import type ResponseModel from '../../models/ResponseModel';
import { setToken, tokenStore } from '../../redux';
import config from '../../config/config';

export const requestAndParse = async (
    route: string,
    payload = {},
    method = 'POST',
) => {
    const rawResponse = await request(route, payload, method);
    const responseBody = rawResponse?.data;
    const status = rawResponse?.status;
    const mockedResponse = {
        body: responseBody ?? {},
        status: status ?? 503,
    };
    if (status === 401 && responseBody?.message === 'Invalid Token') {
        setToken('');
    }

    return mockedResponse;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const request = async (route: string, payload: any, method: string) => {
    const token = tokenStore.getState();

    return axios
        .request({
            url: `${config.url}/${route}`,
            method,
            headers: {
                'Content-Type': 'application/json',
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: token ? `Bearer: ${token}` : '',
            },
            data: method === 'GET' ? undefined : payload,
            params: method === 'GET' ? payload : undefined,
        })
        .catch((error_) => {
            const error = error_ as AxiosError;
            if (error?.response) {
                return error.response;
            }

            console.error(error);
        });
};

export const requestSuccessful = (response: ResponseModel) => {
    return response.status >= 200 && response.status < 300;
};

export const serverError = (response: ResponseModel) => {
    return response.status >= 500;
};
