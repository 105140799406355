import React, { useState, type FC, useEffect } from 'react';
import Table from '../components/Table';
import type DbDataModel from '../models/DbDataModel';
import api from '../services/api';
import LoadingView from '../components/LoadingView';

const Sales: FC = () => {
    const [data, setData] = useState<DbDataModel[]>([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(true);

    const updateData = async () => {
        setLoading(true);
        const { payments, message, success } = await api.getPayments();
        if (success) {
            setData(payments);
            setErrorMessage('');
        } else {
            setData([]);
            setErrorMessage(
                message === 'Forbidden'
                    ? 'You do not have permission to access sales data.'
                    : message,
            );
        }

        setLoading(false);
    };

    useEffect(() => {
        void updateData();
    }, []);

    const columns = [
        'id',
        'full_name',
        'username',
        'payer_email',
        'payment_amount',
        'subscription_type',
        'credits',
        'last_login_date',
        'payment_date',
        'promo_code',
        'new_exp_date',
        'affiliate_id',
        'order_id',
        'language',
        'referral_source',
        'payment_type',
    ];

    return (
        <>
            <div className="text-primary text-3xl pb-4">Reports</div>
            {loading && <LoadingView />}
            {!loading && errorMessage && (
                <div className="text-primary text-xl">
                    Error: {errorMessage}
                </div>
            )}
            {!loading && !errorMessage && (
                <Table title="Sales" data={data} columns={columns} />
            )}
        </>
    );
};

export default Sales;
