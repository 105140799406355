import type DbDataModel from '../../../models/DbDataModel';
import { requestAndParse, requestSuccessful } from '../request';

const getAccountConnectInfo = async (userId: number) => {
    const route = 'admin/get-account-connect-info';
    const payload = { userId };

    const response = await requestAndParse(route, payload, 'GET');

    const success = requestSuccessful(response);
    const teachingCouples = (response.body?.teachingCouples ??
        []) as DbDataModel[];
    const message = response.body?.message as string;

    return { success, teachingCouples, message };
};

export default getAccountConnectInfo;
