import type UsageStatisticsModel from '../../../models/UsageStatisticsModel';
import { requestAndParse, requestSuccessful } from '../request';

const getUsageStatistics = async () => {
    const route = 'admin/get-usage-statistics';
    const payload = {};

    const response = await requestAndParse(route, payload, 'GET');

    const success = requestSuccessful(response);
    const statistics = response.body as UsageStatisticsModel;
    const message = response.body?.message as string;

    return { success, statistics, message };
};

export default getUsageStatistics;
