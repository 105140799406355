import React, { type FC } from 'react';
import { type FieldValues, useForm } from 'react-hook-form';
import { Dialog } from 'primereact/dialog';
import { type Toast } from 'primereact/toast';
import { addDays, getTodayIso } from '../utils/date';
import api from '../services/api';
import type IsoDate from '../models/IsoDate';

const CreatePromoCodeDialog: FC<{
    showPopup: boolean;
    setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
    toast: React.RefObject<Toast>;
    updateData: () => Promise<void>;
}> = ({ showPopup, setShowPopup, toast, updateData }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const createCode = async (data: FieldValues) => {
        const codeData = data as {
            code: string;
            expirationDate: IsoDate;
            numberOfFreeDays: string;
            note: string;
        };

        const { success, message } = await api.createPromoCode(
            codeData.code,
            codeData.expirationDate,
            Number(codeData.numberOfFreeDays),
            codeData.note,
        );

        if (success) {
            setShowPopup(false);
            toast.current?.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Promo code added',
            });
            void updateData();
        } else {
            toast.current?.show({
                severity: 'warn',
                summary: 'Update Failed',
                detail: message ?? 'unknown error',
            });
        }
    };

    return (
        <Dialog
            visible={showPopup}
            header="Create Promo Code"
            onHide={() => {
                setShowPopup(false);
            }}
        >
            <div>
                <form
                    className="flex flex-col items-center justify-center"
                    onSubmit={handleSubmit(createCode)}
                >
                    <div className="flex flex-col items-center">
                        <label htmlFor="code" className="text-primary">
                            Code
                        </label>
                        <input
                            placeholder=""
                            defaultValue=""
                            {...register('code', {
                                required: true,
                                maxLength: 40,
                            })}
                            className="input input-bordered input-primary text-primary m-2"
                        />
                        {errors.code?.type === 'required' && (
                            <span className="text-red-500 mb-2">
                                This field is required
                            </span>
                        )}
                        {errors.code?.type === 'maxLength' && (
                            <span className="text-red-500 mb-2">
                                Code cannot be more than 40 characters
                            </span>
                        )}
                    </div>
                    <div className="flex flex-col items-center">
                        <label
                            htmlFor="expirationDate"
                            className="text-primary"
                        >
                            Expiration Date
                        </label>
                        <input
                            defaultValue={addDays(getTodayIso(), 30)}
                            {...register('expirationDate', {
                                required: true,
                            })}
                            type="date"
                            min={getTodayIso()}
                            className="input input-bordered input-primary text-primary m-2"
                        />
                        {errors.expirationDate?.type === 'required' && (
                            <span className="text-red-500 mb-2">
                                This field is required
                            </span>
                        )}
                    </div>
                    <div className="flex flex-col items-center">
                        <label
                            htmlFor="numberOfFreeDays"
                            className="text-primary text-center"
                        >
                            Number of Free Days
                        </label>
                        <input
                            defaultValue={180}
                            {...register('numberOfFreeDays', {
                                required: true,
                            })}
                            type="number"
                            min={1}
                            max={365}
                            className="input input-bordered input-primary text-primary m-2"
                        />
                        {errors.numberOfFreeDays?.type === 'required' && (
                            <span className="text-red-500 text-center mb-2">
                                This field is required
                            </span>
                        )}
                    </div>
                    <div className="flex flex-col items-center">
                        <label htmlFor="note" className="text-primary">
                            Note
                        </label>
                        <input
                            placeholder=""
                            defaultValue=""
                            {...register('note', {
                                maxLength: 1000,
                            })}
                            className="input input-bordered input-primary text-primary m-2"
                        />
                        {errors.code?.type === 'maxLength' && (
                            <span className="text-red-500 mb-2">
                                Note cannot be more than 1000 characters
                            </span>
                        )}
                    </div>
                    <input type="submit" className="btn btn-primary m-2" />
                </form>
            </div>
        </Dialog>
    );
};

export default CreatePromoCodeDialog;
