import { createReduxModule } from 'hooks-for-redux';

export const [useUsername, { setUsername }, usernameStore] = createReduxModule(
    'username',
    localStorage.getItem('peakdayUser') ?? '',
    {
        setUsername(state: string, username: string) {
            localStorage.setItem('peakdayUser', username);
            return username;
        },
    },
);

export const [useToken, { setToken }, tokenStore] = createReduxModule(
    'token',
    localStorage.getItem('peakdayToken') ?? '',
    {
        setToken(state: string, token: string) {
            localStorage.setItem('peakdayToken', token);
            return token;
        },
    },
);

export const [useMenuOpen, { setMenuOpen }] = createReduxModule(
    'menuOpen',
    localStorage.getItem('peakdayMenuOpen') === 'true',
    {
        setMenuOpen(state: boolean, menuOpen: boolean) {
            localStorage.setItem('peakdayMenuOpen', String(menuOpen));
            return menuOpen;
        },
    },
);

export const logout = () => {
    setUsername('');
    setToken('');
};
