import React, { type FC } from 'react';
import UsageStatistics from '../components/UsageStatistics';

const Stats: FC = () => {
    return (
        <div className="flex flex-col items-center justify-center">
            <div className="text-primary text-3xl pb-4">Usage Statistics</div>
            <UsageStatistics />
        </div>
    );
};

export default Stats;
