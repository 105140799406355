import type DbDataModel from '../../../models/DbDataModel';
import { requestAndParse, requestSuccessful } from '../request';

const getAllAdminNotes = async () => {
    const route = 'admin/get-all-admin-notes';
    const payload = {};

    const response = await requestAndParse(route, payload, 'GET');

    const success = requestSuccessful(response);
    const adminNotes = response.body?.adminNotes as DbDataModel[];
    const message = response.body?.message as string;

    return { success, adminNotes, message };
};

export default getAllAdminNotes;
