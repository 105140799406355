import React from 'react';
import { Provider } from 'hooks-for-redux';
import { RouterProvider } from 'react-router-dom';
import mainRouter from './routing/main-router';
import Layout from './containers/Layout';

function App() {
    return (
        // @ts-expect-error hooks-for-redux does not properly handle TypeScript for its Provider
        <Provider>
            <Layout>
                <link
                    href="https://fonts.googleapis.com/css?family=Poppins"
                    rel="stylesheet"
                />
                <RouterProvider router={mainRouter} />
            </Layout>
        </Provider>
    );
}

export default App;
