import React, { type FC } from 'react';
import { type Toast } from 'primereact/toast';
import { type FieldValues, useForm } from 'react-hook-form';
import type UserModel from '../models/UserModel';
import { type Privilege, type Role } from '../models/UserModel';
import {
    deleteTerraDevice,
    handleAnonymize,
    updateExpirationDate,
    updatePassword,
    updatePermissions,
    updateUserInfo,
    validatePassword,
} from '../helpers/AccountHelpers';
import type IsoDate from '../models/IsoDate';

export type UserData = {
    firstName?: string;
    lastName?: string;
    username: string;
    email: string;
    phone?: string;
    referralSource?: string;
    privilege: Privilege;
    role: Role;
    password?: string;
    expiration: IsoDate;
    anonymize: boolean;
    deleteTerra: boolean;
};

const RoleManagementTab: FC<{
    setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
    user: UserModel | undefined;
    toast: React.RefObject<Toast>;
}> = ({ user, setShowPopup, toast }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data: FieldValues) => {
        const userData = data as UserData;

        let success = await updateUserInfo(userData, user, toast);

        success = success && (await updatePermissions(userData, user, toast));

        success = success && (await updatePassword(userData, user, toast));

        success =
            success && (await updateExpirationDate(userData, user, toast));

        success = success && (await deleteTerraDevice(userData, user, toast));

        success =
            success &&
            (await handleAnonymize(userData, setShowPopup, user, toast));

        if (success) setShowPopup(false);
    };

    const Row: FC<{
        label: string;
        id: string;
        defaultValue?: string;
        required?: boolean;
        validate?: (value: string) => boolean;
        validateError?: string;
        type?: React.HTMLInputTypeAttribute;
    }> = ({
        label,
        id,
        defaultValue,
        required,
        validate,
        validateError,
        type,
    }) => {
        return (
            <>
                <div className="flex items-center">
                    <label htmlFor={id}>{label}</label>
                </div>
                <div className="flex flex-col col-span-3">
                    <input
                        type={type}
                        id={id}
                        defaultValue={defaultValue}
                        className="text-black border border-black rounded text-primary w-full p-1"
                        {...register(id, { required, validate })}
                    />
                    {errors[id]?.type === 'required' && (
                        <span className="text-red-500">
                            This field is required
                        </span>
                    )}
                    {errors[id]?.type === 'validate' && (
                        <span className="text-red-500">{validateError}</span>
                    )}
                </div>
            </>
        );
    };

    const DropdownRow: FC<{
        label: string;
        id: string;
        options: string[];
        defaultValue?: string;
    }> = ({ label, id, options, defaultValue }) => {
        return (
            <>
                <div className="flex items-center">
                    <label htmlFor={id}>{label}</label>
                </div>
                <div className="col-span-3">
                    <select
                        id={id}
                        className="text-black border border-black rounded text-primary w-full p-1"
                        defaultValue={defaultValue}
                        {...register(id, { required: true })}
                    >
                        {options.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
            </>
        );
    };

    return (
        <div>
            <form
                className="flex flex-col items-center"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="grid grid-cols-4 gap-4 w-[600px]">
                    <Row
                        label="First Name"
                        id="firstName"
                        defaultValue={user?.first_name}
                    />
                    <Row
                        label="Last Name"
                        id="lastName"
                        defaultValue={user?.last_name}
                    />
                    <Row
                        required
                        label="Username"
                        id="username"
                        defaultValue={user?.username}
                    />
                    <Row
                        required
                        label="Email Address"
                        id="email"
                        defaultValue={user?.email}
                    />
                    <Row
                        label="Phone Number"
                        id="phone"
                        defaultValue={user?.phone_number}
                    />
                    <Row
                        label="Referral Source"
                        id="referralSource"
                        defaultValue={user?.referral_source}
                    />
                    <DropdownRow
                        label="Privilege"
                        id="privilege"
                        defaultValue={user?.privilege}
                        options={['Super Admin', 'Admin', 'Non-Admin']}
                    />
                    <DropdownRow
                        label="Role"
                        id="role"
                        defaultValue={user?.role}
                        options={['User', 'Medical', 'TC', 'Coach', 'FSI']}
                    />
                    <Row
                        label="New Password"
                        id="password"
                        validate={validatePassword}
                        validateError="Password must be at least 8 characters and contain one lower case letter, one upper case letter, and one number."
                    />
                    <Row
                        label="Subscription Expiration Date"
                        id="expiration"
                        type="date"
                        defaultValue={user?.sub_expires}
                    />
                    <div className="col-span-2">
                        <input
                            type="checkbox"
                            id="anonymize"
                            {...register('anonymize')}
                        />
                        <label htmlFor="anonymize" className="ml-2">
                            Anonymize User
                        </label>
                    </div>
                    <div className="col-span-2">
                        <input
                            type="checkbox"
                            id="deleteTerra"
                            {...register('deleteTerra')}
                        />
                        <label htmlFor="deleteTerra" className="ml-2">
                            Disconnect Terra Device
                        </label>
                    </div>
                </div>
                <input type="submit" className="btn btn-primary mt-4" />
            </form>
        </div>
    );
};

export default RoleManagementTab;
