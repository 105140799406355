export const capitalizeFirstLetter = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
};

export const snakeCaseToString = (snakeCaseString: string) => {
    const words = snakeCaseString.split('_');
    const wordsToUseAllCaps = new Set(['id', 'uid', 'db']);
    const capitalizedWords = words.map((word) => {
        if (wordsToUseAllCaps.has(word)) return word.toUpperCase();
        return capitalizeFirstLetter(word);
    });
    return capitalizedWords.join(' ');
};

// Sleep for the specified number of milliseconds
export const sleep = async (ms: number) => {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve) => setTimeout(resolve, ms));
};
