import type IsoDate from '../models/IsoDate';
import { capitalizeFirstLetter } from './general';

const toStringWithLeadingZeros = (number: number) => {
    const string = number.toString();
    return string.length > 1 ? string : '0' + string;
};

export const getTodayIso = () => {
    const now = new Date();

    const year = now.getFullYear();

    const month: number = now.getMonth() + 1;
    const monthString: string = toStringWithLeadingZeros(month);

    const day: number = now.getDate();
    const dayString: string = toStringWithLeadingZeros(day);

    const todayIso = `${year}-${monthString}-${dayString}`;

    return todayIso;
};

const getTimeDiff = (dateString1: IsoDate, dateString2: IsoDate) => {
    const diff =
        new Date(dateString1).getTime() - new Date(dateString2).getTime();
    return diff;
};

export const getDayDiff = (dateString1: IsoDate, dateString2: IsoDate) => {
    const diffTime = getTimeDiff(dateString1, dateString2);
    const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24));
    const absoluteDiff = Math.abs(diffDays);
    return absoluteDiff;
};

export const isDateOneAfterDateTwo = (
    dateString1: IsoDate,
    dateString2: IsoDate,
) => {
    const diff = getTimeDiff(dateString1, dateString2);
    return diff > 0 && dateString1 !== dateString2;
};

export const dateToIsoDate = (date: Date) => {
    return date.toISOString().split('T')[0];
};

export const addDays = (dateString: IsoDate, daysToAdd: number) => {
    const date = new Date(dateString);
    const newDate = new Date(
        date.getTime() + daysToAdd * (1000 * 60 * 60 * 24),
    );
    return dateToIsoDate(newDate);
};

export const addHours = (timeString: IsoDate, hoursToAdd: number) => {
    const originalDate = new Date('1970-01-01T' + timeString + 'Z');
    const localDate = new Date(
        originalDate.getTime() + hoursToAdd * (1000 * 60 * 60),
    );
    const newTimeString = localDate.toISOString().slice(11, 19);
    return newTimeString;
};

export const getChartDateString = (dateString: IsoDate, isEnglish: boolean) => {
    const locale = isEnglish ? 'en' : 'es';
    const date = new Date(dateString);
    const month = capitalizeFirstLetter(
        date.toLocaleString(locale, { month: 'short', timeZone: 'UTC' }),
    );
    return (
        month +
        (isEnglish && month !== 'May' ? '. ' : ' ') +
        String(date.getUTCDate())
    );
};

export const getNotificationDateString = (
    dateString: IsoDate,
    isEnglish: boolean,
) => {
    const locale = isEnglish ? 'en' : 'es';
    const date = new Date(dateString);
    return date.toLocaleString(locale, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'UTC',
    });
};

export const getYear = (dateString: IsoDate) => {
    return Number(dateString.split('-')[0]);
};
