import { requestAndParse, requestSuccessful } from '../request';

const setPermissions = async (
    userId: number,
    sales: boolean,
    admin: boolean,
    teacher: boolean,
    medical: boolean,
    coach: boolean,
    fsi: boolean,
    // eslint-disable-next-line max-params
) => {
    const route = 'admin/set-permissions';
    const payload = {
        userId,
        sales,
        admin,
        teacher,
        medical,
        coach,
        fsi,
    };

    const response = await requestAndParse(route, payload);

    const success = requestSuccessful(response);
    const message = response.body?.message as string;

    return { success, message };
};

export default setPermissions;
