import React, { useState, type FC } from 'react';
import {
    DataTable,
    type DataTableSelectionChangeEvent,
} from 'primereact/datatable';
import { CSVLink } from 'react-csv';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { snakeCaseToString } from '../utils/general';
import type DbDataModel from '../models/DbDataModel';

type TableProps = {
    data: DbDataModel[];
    title: string;
    columns?: string[];
    frozenColumns?: string[];
    onClick?: (row: DbDataModel) => void;
};

const Table: FC<TableProps> = ({
    data,
    onClick,
    columns,
    title,
    frozenColumns,
}) => {
    columns = columns ?? Object.keys(data[0] ?? {});

    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const onSelectionChange = (
        event: DataTableSelectionChangeEvent<DbDataModel[]>,
    ) => {
        if (onClick) {
            onClick(event.value as DbDataModel);
        }
    };

    const onGlobalFilterChange: React.ChangeEventHandler<HTMLInputElement> = (
        event,
    ) => {
        setGlobalFilterValue(event.target.value);
    };

    const header = (
        <div className="flex justify-between items-center">
            <h1 className="text-2xl">
                {title} ({data.length} rows)
            </h1>
            <div>
                <div className="btn btn-primary mr-4">
                    <CSVLink
                        data={data}
                        headers={columns.map((column) => ({
                            key: column,
                            label: snakeCaseToString(column),
                        }))}
                        filename={`${title.replace(/\s/g, '')}.csv`}
                    >
                        Export
                    </CSVLink>
                </div>
                <InputText
                    value={globalFilterValue}
                    placeholder="Keyword Search"
                    onChange={onGlobalFilterChange}
                />
            </div>
        </div>
    );

    return (
        <DataTable
            showGridlines
            stripedRows
            paginator
            removableSort
            scrollable
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            sortMode="multiple"
            value={data}
            size="small"
            tableStyle={{ width: '100%' }}
            selectionMode="single"
            globalFilterFields={columns}
            globalFilterMatchMode="contains"
            globalFilter={globalFilterValue}
            header={header}
            onSelectionChange={onSelectionChange}
        >
            {columns.map((col) => (
                <Column
                    key={col}
                    sortable
                    filter
                    field={col}
                    filterMatchMode="contains"
                    filterField={col}
                    header={snakeCaseToString(col)}
                    frozen={frozenColumns?.includes(col)}
                />
            ))}
        </DataTable>
    );
};

export default Table;
