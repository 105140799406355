import { requestAndParse, requestSuccessful } from '../request';

const deleteTerraDevice = async (userId: number) => {
    const route = 'admin/delete-terra-device';
    const payload = { userId };

    const response = await requestAndParse(route, payload, 'DELETE');

    const success = requestSuccessful(response);
    const message = response.body?.message as string;

    return { success, message };
};

export default deleteTerraDevice;
