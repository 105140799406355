import type DbDataModel from '../../../models/DbDataModel';
import type UserTypeModel from '../../../models/UserTypeModel';
import { requestAndParse, requestSuccessful } from '../request';

const getUsers = async (userType?: UserTypeModel) => {
    const route = 'admin/get-users';
    const payload = {
        userType,
    };

    const response = await requestAndParse(route, payload, 'GET');

    const success = requestSuccessful(response);
    const users = response.body?.users as DbDataModel[];
    const message = response.body?.message as string;

    return { success, users, message };
};

export default getUsers;
