import React, { type FC } from 'react';
import LoadingSpinner from './LoadingSpinner';

const LoadingView: FC<{ size?: number }> = ({ size }) => {
    return (
        <div className="flex items-center justify-center flex-1">
            <LoadingSpinner size={size} />
        </div>
    );
};

export default LoadingView;
