import { requestAndParse, requestSuccessful } from '../request';

const deletePromoCode = async (id: number) => {
    const route = 'admin/delete-promo-code';
    const payload = { id };

    const response = await requestAndParse(route, payload, 'DELETE');

    const success = requestSuccessful(response);
    const message = response.body?.message as string;

    return { success, message };
};

export default deletePromoCode;
