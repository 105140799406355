import React, { type FC, useEffect, useState } from 'react';
import type UsageStatisticsModel from '../models/UsageStatisticsModel';
import api from '../services/api';
import LoadingView from './LoadingView';

const UsageStatistics: FC = () => {
    const [usageStatistics, setUsageStatistics] = useState<
        UsageStatisticsModel | undefined
    >();
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const loadStatistics = async () => {
        setLoading(true);
        const { success, statistics, message } = await api.getUsageStatistics();
        if (success) {
            setUsageStatistics(statistics);
            setErrorMessage('');
        } else {
            setErrorMessage(message);
        }

        setLoading(false);
    };

    useEffect(() => {
        void loadStatistics();
    }, []);

    return (
        <>
            {loading && <LoadingView />}
            {!loading && errorMessage && (
                <div className="text-primary text-xl">
                    Error: {errorMessage}
                </div>
            )}
            {!loading && !errorMessage && (
                <div className="grid grid-cols-3 max-w-5xl">
                    <div className="border-2 border-primary">
                        <div className="text-center text-xl text-primary border-b-2 border-primary p-2">
                            Subscribers
                        </div>
                        <div className="p-2">
                            <div className="text-lg text-primary">
                                {usageStatistics?.monthlySubscribers} active
                                monthly
                            </div>
                            <div className="text-lg text-primary">
                                {usageStatistics?.annualSubscribers} active
                                annual
                            </div>
                            <div className="text-lg text-primary">
                                {usageStatistics?.totalSubscribers} total
                                subscribers
                            </div>
                            <div className="text-lg text-primary">
                                {usageStatistics?.activeNonSubscribers} active
                                non-subscribers
                            </div>
                            <div className="text-lg text-primary">
                                ${usageStatistics?.monthlyRevenue} equivalent
                                monthly sales
                            </div>
                        </div>
                    </div>
                    <div className="border-2 border-primary">
                        <div className="text-center text-xl text-primary border-b-2 border-primary p-2">
                            Logins
                        </div>
                        <div className="p-2">
                            <div className="text-lg text-primary">
                                {usageStatistics?.loginsToday} users today
                            </div>
                            <div className="text-lg text-primary">
                                {usageStatistics?.loginsPastTwoDays} users
                                yesterday and today
                            </div>
                            <div className="text-lg text-primary">
                                {usageStatistics?.loginsPastThreeDays} users in
                                previous 3 days
                            </div>
                            <div className="text-lg text-primary">
                                {usageStatistics?.loginsPastSevenDays} users in
                                previous 7 days
                            </div>
                        </div>
                    </div>
                    <div className="border-2 border-primary">
                        <div className="text-center text-xl text-primary border-b-2 border-primary p-2">
                            Signups
                        </div>
                        <div className="p-2">
                            <div className="text-lg text-primary">
                                {usageStatistics?.signupsToday} signups today
                            </div>
                            <div className="text-lg text-primary">
                                {usageStatistics?.signupsPastTwoDays} signups
                                yesterday and today
                            </div>
                            <div className="text-lg text-primary">
                                {usageStatistics?.signupsPastThreeDays} signups
                                in previous 3 days
                            </div>
                            <div className="text-lg text-primary">
                                {usageStatistics?.signupsPastSevenDays} signups
                                in previous 7 days
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default UsageStatistics;
