import type AdminNoteModel from '../../../models/AdminNoteModel';
import { requestAndParse, requestSuccessful } from '../request';

const getAdminNotes = async (userId: number) => {
    const route = 'admin/get-admin-notes';
    const payload = { userId };

    const response = await requestAndParse(route, payload, 'GET');

    const success = requestSuccessful(response);
    const notes = (response.body?.notes ?? []) as AdminNoteModel[];
    const message = response.body?.message as string;

    return { success, notes, message };
};

export default getAdminNotes;
