import React, { useState, type FC, useEffect } from 'react';
import { type Toast } from 'primereact/toast';
import { type FieldValues, useForm } from 'react-hook-form';
import type UserModel from '../models/UserModel';
import api from '../services/api';
import type AdminNoteModel from '../models/AdminNoteModel';
import LoadingView from './LoadingView';

const AdminNotesTab: FC<{
    setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
    user: UserModel | undefined;
    toast: React.RefObject<Toast>;
}> = ({ user, setShowPopup, toast }) => {
    const [loading, setLoading] = useState(true);
    const [adminNotes, setAdminNotes] = useState<AdminNoteModel[]>([]);

    const loadAdminNotes = async () => {
        if (user) {
            setLoading(true);
            const { notes, success, message } = await api.getAdminNotes(
                user.id,
            );
            if (success) {
                setAdminNotes(notes);
            } else {
                toast.current?.show({
                    severity: 'warn',
                    summary: 'Loading Notes Failed',
                    detail: message ?? 'unknown error',
                });
            }

            setLoading(false);
        }
    };

    useEffect(() => {
        void loadAdminNotes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data: FieldValues) => {
        const note = data.note as string;

        const { success, message } = await api.addAdminNote(
            user?.id ?? 0,
            note,
        );
        if (success) {
            setShowPopup(false);
            toast.current?.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Note Added',
            });
        } else {
            toast.current?.show({
                severity: 'warn',
                summary: 'Adding Note Failed',
                detail: message ?? 'unknown error',
            });
        }
    };

    return (
        <div className="flex flex-col items-center">
            <div className="w-[800px] h-96 overflow-y-scroll">
                {loading ? (
                    <LoadingView />
                ) : (
                    adminNotes.map((note) => (
                        <div key={note.id} className="mb-2">
                            {note.note_date} ({note.note_author_name}):{' '}
                            {note.note}
                        </div>
                    ))
                )}
            </div>
            <form
                className="flex flex-row items-center"
                onSubmit={handleSubmit(onSubmit)}
            >
                <label htmlFor="note">Enter new note</label>
                <input
                    {...register('note', { required: true })}
                    className="input input-bordered input-primary text-primary m-2 w-96"
                />
                {errors.note && (
                    <span className="text-red-500 mb-2">
                        This field is required
                    </span>
                )}
                <input type="submit" className="btn btn-primary m-2" />
            </form>
        </div>
    );
};

export default AdminNotesTab;
