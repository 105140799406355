import React, { useState, type FC, useEffect } from 'react';
import Table from '../components/Table';
import type DbDataModel from '../models/DbDataModel';
import api from '../services/api';
import LoadingView from '../components/LoadingView';

const ChartReviews: FC = () => {
    const [data, setData] = useState<DbDataModel[]>([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(true);

    const updateData = async () => {
        setLoading(true);
        const { chartReviews, message, success } = await api.getChartReviews();
        if (success) {
            setData(chartReviews);
            setErrorMessage('');
        } else {
            setData([]);
            setErrorMessage(message);
        }

        setLoading(false);
    };

    useEffect(() => {
        void updateData();
    }, []);

    return (
        <>
            <div className="text-primary text-3xl pb-4">Reports</div>
            {loading && <LoadingView />}
            {!loading && errorMessage && (
                <div className="text-primary text-xl">
                    Error: {errorMessage}
                </div>
            )}
            {!loading && !errorMessage && (
                <Table title="Chart Reviews" data={data} />
            )}
        </>
    );
};

export default ChartReviews;
