import { useState, useEffect } from 'react';

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    function changeWindowDimensions() {
        setWindowDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }

    useEffect(() => {
        window.addEventListener('resize', changeWindowDimensions);

        return () => {
            window.removeEventListener('resize', changeWindowDimensions);
        };
    }, []);

    return windowDimensions;
}
