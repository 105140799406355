import { type Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import type UserModel from '../models/UserModel';
import api from '../services/api';
import { type UserData } from '../components/RoleManagementTab';
import type DbDataModel from '../models/DbDataModel';
import { getTodayIso } from '../utils/date';

export const updatePermissions = async (
    userData: UserData,
    user: UserModel | undefined,
    toast: React.RefObject<Toast>,
) => {
    // Skip if nothing changed
    if (
        userData.privilege === user?.privilege &&
        userData.role === user?.role
    ) {
        return true;
    }

    const admin =
        userData.privilege === 'Admin' || userData.privilege === 'Super Admin';
    const sales = userData.privilege === 'Super Admin';

    let teacher = false;
    let medical = false;
    let coach = false;
    let fsi = false;

    switch (userData.role) {
        case 'TC': {
            teacher = true;
            break;
        }

        case 'Medical': {
            medical = true;
            break;
        }

        case 'Coach': {
            coach = true;
            break;
        }

        case 'FSI': {
            fsi = true;
            break;
        }

        default: {
            break;
        }
    }

    const { success, message } = await api.setPermissions(
        user?.id ?? 0,
        sales,
        admin,
        teacher,
        medical,
        coach,
        fsi,
    );

    if (success) {
        if (user) {
            user.privilege = userData.privilege;
            user.role = userData.role;
        }

        toast.current?.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Permissions updated',
        });
    } else {
        toast.current?.show({
            severity: 'warn',
            summary: 'Update Failed',
            detail: message ?? 'unknown error',
        });
    }

    return success;
};

export const updateUserInfo = async (
    userData: UserData,
    user: UserModel | undefined,
    toast: React.RefObject<Toast>,
) => {
    // Skip if nothing changed
    if (
        userData.firstName === user?.first_name &&
        userData.lastName === user?.last_name &&
        userData.email === user?.email &&
        userData.phone === user.phone_number &&
        userData.referralSource === user.referral_source &&
        userData.username === user.username
    ) {
        return true;
    }

    const { success, message } = await api.setUserInfo(
        user?.id ?? 0,
        userData.firstName,
        userData.lastName,
        userData.email,
        userData.phone,
        userData.referralSource,
        userData.username,
    );

    if (success) {
        if (user) {
            user.first_name = userData.firstName;
            user.last_name = userData.lastName;
            user.username = userData.username;
            user.email = userData.email;
            user.phone_number = userData.phone;
            user.referral_source = userData.referralSource;
        }

        toast.current?.show({
            severity: 'success',
            summary: 'Success',
            detail: 'User data updated',
        });
    } else {
        toast.current?.show({
            severity: 'warn',
            summary: 'Update Failed',
            detail: message ?? 'unknown error',
        });
    }

    return success;
};

export const updatePassword = async (
    userData: UserData,
    user: UserModel | undefined,
    toast: React.RefObject<Toast>,
) => {
    // Skip if nothing changed
    if (!userData.password) {
        return true;
    }

    const { success, message } = await api.resetPassword(
        user?.id ?? 0,
        userData.password,
    );

    if (success) {
        toast.current?.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Password updated',
        });
    } else {
        toast.current?.show({
            severity: 'warn',
            summary: 'Update Failed',
            detail: message ?? 'unknown error',
        });
    }

    return success;
};

export const updateExpirationDate = async (
    userData: UserData,
    user: UserModel | undefined,
    toast: React.RefObject<Toast>,
) => {
    // Skip if nothing changed
    if (userData.expiration === user?.sub_expires) {
        return true;
    }

    const { success, message } = await api.setExpirationDate(
        user?.id ?? 0,
        userData.expiration,
    );

    if (success) {
        if (user) {
            user.sub_expires = userData.expiration;
        }

        toast.current?.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Expiration Date updated',
        });
    } else {
        toast.current?.show({
            severity: 'warn',
            summary: 'Update Failed',
            detail: message ?? 'unknown error',
        });
    }

    return success;
};

export const handleAnonymize = async (
    userData: UserData,
    setShowPopup: React.Dispatch<React.SetStateAction<boolean>>,
    user: UserModel | undefined,
    toast: React.RefObject<Toast>,
) => {
    // Skip if nothing changed
    if (!userData.anonymize) {
        return true;
    }

    const anonymizeUser = async () => {
        const { success, message } = await api.anonymizeUser(user?.id ?? 0);
        if (success) {
            setShowPopup(false);

            if (user) {
                user.first_name = 'Anonymous';
                user.last_name = getTodayIso();
                user.username = `anonymous${user.id}`;
                user.email = '';
                user.phone_number = '';
            }

            toast.current?.show({
                severity: 'success',
                summary: 'Success',
                detail: 'User anonymized',
            });
        } else {
            const errorMessage =
                message === 'Forbidden'
                    ? 'You must have super admin privileges to anonymize a user'
                    : message;
            toast.current?.show({
                severity: 'warn',
                summary: 'Update Failed',
                detail: errorMessage ?? 'unknown error',
            });
        }
    };

    confirmDialog({
        message: `Are you sure you want to anonymize user ${
            user?.username ?? ''
        }? This user will no longer be able to log into their account.`,
        accept: anonymizeUser,
    });

    return false; // So RoleManagementTab doesn't close the popup; anonymizeUser will close the popup if successful
};

export const deleteTerraDevice = async (
    userData: UserData,
    user: UserModel | undefined,
    toast: React.RefObject<Toast>,
) => {
    // Skip if nothing changed
    if (!userData.deleteTerra) {
        return true;
    }

    const { success, message } = await api.deleteTerraDevice(user?.id ?? 0);

    if (success) {
        if (user) {
            user.terra_device = '';
        }

        toast.current?.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Device removed from Terra',
        });
    } else {
        toast.current?.show({
            severity: 'warn',
            summary: 'Update Failed',
            detail: message ?? 'unknown error',
        });
    }

    return success;
};

export const validatePassword = (password: string) => {
    return (
        !password ||
        (password.length >= 8 &&
            password.length <= 35 &&
            /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/.test(password))
    );
};

export const connectAccount = async (
    userId: number,
    teacherRow: DbDataModel,
    toast: React.RefObject<Toast>,
) => {
    const { success, message } = await api.connectAccount(
        userId,
        teacherRow.id,
    );

    if (success) {
        toast.current?.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Account connected',
        });
        teacherRow.connected = true;
    } else {
        const errorMessage =
            message === 'Forbidden'
                ? 'You must have super admin privileges to manage account connections'
                : message;
        toast.current?.show({
            severity: 'warn',
            summary: 'Update Failed',
            detail: errorMessage ?? 'unknown error',
        });
    }

    return success;
};

export const disconnectAccount = async (
    userId: number,
    teacherRow: DbDataModel,
    toast: React.RefObject<Toast>,
) => {
    const { success, message } = await api.disconnectAccount(
        userId,
        teacherRow.id,
    );

    if (success) {
        toast.current?.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Account disconnected',
        });
        teacherRow.connected = false;
    } else {
        const errorMessage =
            message === 'Forbidden'
                ? 'You must have super admin privileges to manage account connections'
                : message;
        toast.current?.show({
            severity: 'warn',
            summary: 'Update Failed',
            detail: errorMessage ?? 'unknown error',
        });
    }

    return success;
};
