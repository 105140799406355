import type DbDataModel from '../../../models/DbDataModel';
import { requestAndParse, requestSuccessful } from '../request';

const getPromoCodes = async (validOnly?: boolean) => {
    const route = 'admin/get-promo-codes';
    const payload = { validOnly };

    const response = await requestAndParse(route, payload, 'GET');

    const success = requestSuccessful(response);
    const promoCodes = response.body?.promoCodes as DbDataModel[];
    const message = response.body?.message as string;

    return { success, promoCodes, message };
};

export default getPromoCodes;
